import React from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormErrorMessage,
  ModalCloseButton,
  Input,
  FormLabel,
  FormControl,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { BACKEND_URL } from '../../utils/constants';

const WithdrawEthModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const toast = useToast();

  const onSubmit = async (data) => {
    // Validate withdrawal amount
    if (!data.amount || data.amount <= 0) {
      toast({
        title: 'Invalid amount.',
        description: 'Please enter a valid amount to withdraw.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      // Send POST request to backend
      const response = await fetch(`${BACKEND_URL}/user/withdraw`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          amount: 1e18 * data.amount, // Convert to Wei
        }),
      });

      const result = await response.json();

      if (response.ok) {
        // Show success toast if withdrawal is successful with transaction hash
        toast({
          title: 'Withdrawal Successful.',
          description: `Successfully withdrew ${data.amount} ETH. Transaction Hash: ${result.message}`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        // Show error toast if the request fails
        toast({
          title: 'Withdrawal Failed.',
          description: result.message || 'There was an issue with your withdrawal.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }

    } catch (error) {
      // Handle errors
      console.error('Error withdrawing ETH:', error);
      toast({
        title: 'Withdrawal Failed.',
        description: 'An error occurred. Please try again later.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }

    // Close modal after submitting
    onClose();
  };

  return (
    <>
      <Button onClick={onOpen} type="submit" colorScheme="blue">Withdraw ETH</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent mt="1">
          <ModalHeader>Withdraw ETH</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl id="amount" mb={4} isInvalid={errors.amount}>
                <FormLabel>Amount (ETH)</FormLabel>
                <Input
                  type="number"
                  step="0.01"
                  {...register('amount', { required: true, min: 0.01 })}
                  placeholder="Enter amount"
                  borderRadius="0"
                  boxShadow="4px 4px 0px #000"
                  borderColor="customGray.500"
                  _hover={{ borderColor: 'customGray.700' }}
                />
                {errors.amount && (
                  <FormErrorMessage>
                    {errors.amount.type === 'required' ? 'Amount is required' : 'Amount must be greater than 0'}
                  </FormErrorMessage>
                )}
              </FormControl>

              <FormControl id="note" mb={4}>
                <FormLabel>Note (Optional)</FormLabel>
                <Input
                  {...register('note')}
                  placeholder="Add a note (optional)"
                  borderRadius="0"
                  boxShadow="4px 4px 0px #000"
                  borderColor="customGray.500"
                  _hover={{ borderColor: 'customGray.700' }}
                />
              </FormControl>

              <Button type="submit" w="full" colorScheme="blue" mt={4}>
                Withdraw ETH
              </Button>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default WithdrawEthModal;
