import React from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormErrorMessage,
  ModalCloseButton,
  Input,
  FormLabel,
  FormControl,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useAccount } from 'wagmi';
import { UNIPUMP_ABI, UNIPUMP_CONTRACT_ADDRESS } from '../../utils/constants';
import { ethers } from 'ethers';

const DepositEthModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const toast = useToast();
  const { address } = useAccount();

  const onSubmit = async (data) => {
    try {
      const web3Provider = new ethers.BrowserProvider(window.ethereum);
      const userSigner = await web3Provider.getSigner();
      const contract = new ethers.Contract(UNIPUMP_CONTRACT_ADDRESS, UNIPUMP_ABI, userSigner);

      // Send deposit transaction
      const tx = await contract.deposit({ value: ethers.parseEther(data.amount) });
      await tx.wait(); // Wait for the transaction to be mined

      // Show success toast with transaction hash
      toast({
        title: 'Deposit Successful.',
        description: `Successfully deposited ${data.amount} ETH. Transaction Hash: ${tx.hash}`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      console.log('Transaction successful:', tx);
    } catch (error) {
      console.error(error);
      // Show error toast
      toast({
        title: 'Deposit Failed.',
        description: 'There was an issue with your deposit.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Button onClick={onOpen} type="submit" colorScheme="blue">Deposit ETH</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent mt="1">
          <ModalHeader>Deposit ETH</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl id="amount" mb={4} isInvalid={errors.amount}>
                <FormLabel>Amount (ETH)</FormLabel>
                <Input
                  type="number"
                  step="0.01"
                  {...register('amount', { required: true, min: 0.01 })}
                  placeholder="Enter amount"
                  borderRadius="0"
                  boxShadow="4px 4px 0px #000"
                  borderColor="customGray.500"
                  _hover={{ borderColor: 'customGray.700' }}
                />
                {errors.amount && (
                  <FormErrorMessage>
                    {errors.amount.type === 'required' ? 'Amount is required' : 'Amount must be greater than 0'}
                  </FormErrorMessage>
                )}
              </FormControl>

              <FormControl id="note" mb={4}>
                <FormLabel>Note (Optional)</FormLabel>
                <Input
                  {...register('note')}
                  placeholder="Add a note (optional)"
                  borderRadius="0"
                  boxShadow="4px 4px 0px #000"
                  borderColor="customGray.500"
                  _hover={{ borderColor: 'customGray.700' }}
                />
              </FormControl>

              <Button type="submit" w="full" colorScheme="blue" mt={4}>
                Deposit ETH
              </Button>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DepositEthModal;
