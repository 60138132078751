import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TradingPage from './pages/TradingPage/TradingPage';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme'; // Import your custom theme
import DisplayPage from './pages/TradingPage/DisplayPage';
import TokenNotFound from './pages/NotFoundPage/TokenNotFound';
import HomePage from './pages/HomePage/HomePage';
import { Web3Provider } from './components/common/Web3Provider';

function App() {
  return (

    <ChakraProvider theme={theme}>
      <Web3Provider>
        <Router>
            <Routes>
                <Route path="/" element={<HomePage></HomePage>} />
                <Route path="/:tokenid" element={<TradingPage />} />
                <Route path="/404" element={<TokenNotFound></TokenNotFound>}></Route>

            </Routes>
        </Router>
        </Web3Provider>
  </ChakraProvider>

    
  );
}

export default App;
