export function convertToCandlesticks(ticks, intervalMinutes = 1) {
    const candlesticks = [];
    const intervalMs = intervalMinutes * 60 * 1000; // Convert interval to milliseconds
  
    // Group ticks by time interval
    const groupedTicks = ticks.reduce((acc, tick) => {
      const intervalStart = Math.floor(new Date(tick.timestamp).getTime() / intervalMs) * intervalMs;
      
      if (!acc[intervalStart]) {
        acc[intervalStart] = [];
      }
      acc[intervalStart].push(tick);
      return acc;
    }, {});
  
    for (const [intervalStart, ticks] of Object.entries(groupedTicks)) {
      const open = ticks[0].price;
      const high = Math.max(...ticks.map(tick => tick.price));
      const low = Math.min(...ticks.map(tick => tick.price));
      const close = ticks[ticks.length - 1].price;
  
      candlesticks.push({
        time: new Date(parseInt(intervalStart)),
        open,
        high,
        low,
        close,
        volume: ticks.reduce((total, tick) => total + tick.volume.eth, 0) // Total volume in ETH
      });
    }
  
    return candlesticks;
  }