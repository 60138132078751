import React, { useEffect, useState } from 'react';
import { Box, Button, ButtonGroup, Flex, Text, Progress, Tabs, TabList, TabPanels, Tab, TabPanel, Link } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import CustomChart from '../../components/common/CustomChart'; 
import Trading from './Trading';
import TradesTable from './TradesTable';
import Chatbox from './ChatBox.jsx';
import Header from '../../components/common/Header';
import axios from 'axios';
import { BACKEND_URL, BACKEND_WS_URL, BONDING_MCAP, MIN_MIGRATION_ETH } from '../../utils/constants';
import { convertToCandlesticks } from '../../utils/utils.js';

const TradingPage = () => {
  const [tokenData, setTokenData] = useState();
  const { tokenid } = useParams();
  const [tickData, setTickData] = useState([]);
  const [userData, setUserData] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [candleSticks, setCandleSticks] = useState({
    "1m": [],
    "5m": [],
    "15m": []
  });
  const [timeframe, setTimeframe] = useState('1m');
  const [bondingProgress, setBondingProgress] = useState(0); // Dummy progress percentage

  const handleTimeframeChange = (newTimeframe) => {
    setTimeframe(newTimeframe);
  };

  useEffect(() => {
    const fetchTokenData = async () => {
      try {
        const response = await axios.post(`${BACKEND_URL}/token/fetch`, { tokenId: tokenid });
        const result = response.data;
        if (result && result.data) {
          setTokenData(result.data);
        }
      } catch (error) {
        console.error('Error fetching token data:', error);
      }

      const ws = new WebSocket(`${BACKEND_WS_URL}`);

      ws.onopen = () => {
        ws.send(JSON.stringify({
          action: 'subscribe',
          type: "token",
          tokenId: tokenid,
        }));
      };

      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        
        if (data.type === 'update') {

            setTickData((prevTickData) => {
              if(prevTickData.findIndex(tick => tick._id === data.tickData._id) === -1 && data.tickData) {
                return [data.tickData, ...prevTickData]
              } else {
                return [...prevTickData]
              }
            });
          
            setTokenData((prevTokenData) => {
              if(prevTokenData.chatMessages.findIndex((chatMsg) => chatMsg._id === data.chatMessages._id) == -1 && data.chatMessages) {
                return {
                  ...prevTokenData,
                  chatMessages: [...prevTokenData.chatMessages, data.chatMessages],
                }  
              } else {
                return prevTokenData
              }
            });
            
        } else if (data.type === "initialData") {
          setTickData((prevTickData) => [...data.tickData, ...prevTickData]);
          setCandleSticks(data.candleSticks);
        }

        // Update bonding progress
        let ethInBondingPool = data.realReserves.eth;
        let _bondingProgress = 100 * (ethInBondingPool / MIN_MIGRATION_ETH);
        setBondingProgress(_bondingProgress);
      };
    };

    fetchTokenData();
  }, [tokenid]);

  useEffect(() => {
    if (tickData.length > 0) {
      let _tickData = tickData.sort((a, b) => {
        return new Date(a.timestamp) - new Date(b.timestamp);
      });
      let newCandleSticks1m = convertToCandlesticks(_tickData, 1);
      let newCandleSticks5m = convertToCandlesticks(_tickData, 5);
      let newCandleSticks15m = convertToCandlesticks(_tickData, 15);
      setCandleSticks({
        "1m": newCandleSticks1m,
        "5m": newCandleSticks5m,
        "15m": newCandleSticks15m
      });
    }
  }, [tickData]);

  return (
    <Box bg="#1A202C" color="white" minH="100vh" p={5}>
      {/* Top Bar */}
      <Header userData={userData} setUserData={setUserData} setIsLoggedIn={setIsLoggedIn}></Header>

      {/* Main Content */}
      <Flex>
        {/* Chart Section */}
        <Box flex="40" bg="#2D3748" p={4} borderRadius="md" mb={{ base: 4, md: 0 }}>
          <Flex justifyContent="space-between" alignItems="center" mb={4}>
            <Text fontSize="2xl">{tokenData && tokenData.name}</Text>
            <Link fontSize="xl" href='/'>[Go Back]</Link>
          </Flex>

          {/* Bonding Curve Progress */}
          <Box mb={4}>
            <Text fontSize="lg" mb={2}>Bonding Curve Progress</Text>
            <Progress value={bondingProgress} size="sm" colorScheme="green" />
          </Box>

          {/* Custom Chart */}
          <div>
            <ButtonGroup spacing="2" mb="4">
              <Button 
                onClick={() => handleTimeframeChange('1m')} 
                colorScheme={timeframe === '1m' ? 'blue' : 'gray'} 
                size="sm" 
                px={3}
              >
                1m
              </Button>
              <Button 
                onClick={() => handleTimeframeChange('5m')} 
                colorScheme={timeframe === '5m' ? 'blue' : 'gray'} 
                size="sm" 
                px={3}
              >
                5m
              </Button>
              <Button 
                onClick={() => handleTimeframeChange('15m')} 
                colorScheme={timeframe === '15m' ? 'blue' : 'gray'} 
                size="sm" 
                px={3}
              >
                15m
              </Button>
            </ButtonGroup>

            <CustomChart timeframe={timeframe} data={candleSticks} />
          </div>
        </Box>

        {/* Tabs Section */}
        <Box flex="40" w={{ base: '100%', md: '300px' }} bg="#2D3748" p={4} borderRadius="md" ml={{ md: 4 }}>
          <Tabs variant="enclosed" colorScheme="teal">
            <TabList>
              <Tab>Chat</Tab>
              <Tab>Trade List</Tab>
            </TabList>

            <TabPanels>
              <TabPanel p={0} h="450px" overflowX="hidden" overflowY="auto">
                <Chatbox userData={userData} tokenData={tokenData}/>
              </TabPanel>
              <TabPanel p={0} h="450px" overflowX="hidden" overflowY="auto">
                <TradesTable trades={tickData} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>

        {/* Trading Section */}
        <Box flex="20" w={{ base: '100%', md: '300px' }} bg="#2D3748" p={4} borderRadius="md" ml={{ md: 4 }}>
          <Trading tokenData={tokenData} userData={userData} setUserData={setUserData}></Trading>
        </Box>
      </Flex>
    </Box>
  );
};

export default TradingPage;
