import { WagmiProvider, createConfig, http } from "wagmi";
import { mainnet, sepolia } from "wagmi/chains";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ConnectKitProvider, getDefaultConfig } from "connectkit";
import { MAINNET_RPC,SEPOLIA_RPC } from "../../utils/constants";
import { ethers } from "ethers";

const fallbackProvider = new ethers.JsonRpcProvider(MAINNET_RPC);

const config = createConfig(
  getDefaultConfig({
    // Your dApps chains
      chains: [mainnet,sepolia],
      transports: {
        // RPC URL for each chain
      [mainnet.id]: http(
        MAINNET_RPC,
      ),
      [sepolia.id]: http(
        SEPOLIA_RPC,
      )
    },

    // Required API Keys
    walletConnectProjectId: "02aa6131fe38f3f0831f8b48f33200b7",

    // Required App Info
    appName: "Your App Name",

    // Optional App Info
    appDescription: "Your App Description",
    appUrl: "https://family.co", // your app's url
    appIcon: "https://family.co/logo.png", // your app's icon, no bigger than 1024x1024px (max. 1MB)
  }),
);

const queryClient = new QueryClient();

export const Web3Provider = ({ children }) => {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <ConnectKitProvider>{children}</ConnectKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};