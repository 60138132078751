import React, { useEffect, useState } from 'react';
import { Box, Flex, Button, Text, Input, Image, Link, IconButton, useToast } from '@chakra-ui/react';
import { FaTwitter, FaTelegramPlane, FaGlobe } from 'react-icons/fa';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { BACKEND_URL } from '../../utils/constants';

const Trading = ({ tokenData, userData,setUserData}) => {
  const [tradeType, setTradeType] = useState('');
  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();
  const toast = useToast();

  const [userEthBalance, setUserEthBalance] = useState(0);
  const [userTokenBalance, setUserTokenBalance] = useState(0);

  async function updateUserData() {
    const res = await fetch(`${BACKEND_URL}/user/getUser`, {
        credentials: 'include',
    });
    let _res = await res.json()
    if(_res.success) {
      await setUserData(_res.data)
    }
  }

  useEffect(() => {
    if (userData && userData.currentBalance != undefined) {
      setUserEthBalance(userData?.currentBalance || 0);
    }
    if (userData && userData.tokens && tokenData && tokenData.tokenId) {
      setUserTokenBalance(userData?.tokens?.find(token => token.tokenId === tokenData.tokenId)?.amount || 0);
    }
  }, [tokenData, userData]);

  const handleTradeTypeChange = (type) => setTradeType(type);

  const onSubmit = async (data) => {
    const { tradeAmount } = data;

    if (tradeType && tradeAmount && tokenData) {
      try {
        const response = await axios.post(`${BACKEND_URL}/token/trade`, {
          tokenId: tokenData.tokenId,
          tradeAmount: (tradeAmount * 1e18).toString(),
          tradeType: tradeType
        }, { withCredentials: true });

        toast({
          title: 'Trade Successful',
          description: `Trade placed successfully: ${response.data.message}`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        reset();
        setTradeType('');
        await updateUserData()
      } catch (error) {
        console.error('Error placing trade:', error);
        toast({
          title: 'Trade Failed',
          description: 'Failed to place trade. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: 'Incomplete Data',
        description: 'Please select a trade type, enter an amount, and ensure token data is available.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const setPercentage = (percentage) => {
    const amount = tradeType === 'Buy' ? userEthBalance : userTokenBalance;
    const tradeAmount = (amount * percentage) / 1e18;
    setValue('tradeAmount', tradeAmount);
  };

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Button
          variant={tradeType === 'Buy' ? 'solid' : 'outlineGreen'}
          w="48%"
          onClick={() => handleTradeTypeChange('Buy')}
        >
          Buy
        </Button>
        <Button
          variant={tradeType === 'Sell' ? 'solid' : 'outlineRed'}
          w="48%"
          onClick={() => handleTradeTypeChange('Sell')}
        >
          Sell
        </Button>
      </Flex>

      <Text mb={2}>Amount in $ETH</Text>
      <Flex mb={2} justifyContent="space-between">
        <Button onClick={() => setPercentage(0.1)}>10%</Button>
        <Button onClick={() => setPercentage(0.25)}>25%</Button>
        <Button onClick={() => setPercentage(1)}>100%</Button>
      </Flex>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          placeholder="0.0"
          size="lg"
          mb={2}
          {...register('tradeAmount', {
            required: 'Trade amount is required',
            min: {
              value: 0.0001,
              message: 'Trade amount must be greater than 0'
            }
          })}
        />
        {errors.tradeAmount && <Text color="red.500">{errors.tradeAmount.message}</Text>}

        <Button w="full" colorScheme="green" type="submit" isDisabled={!tradeType}>
          Place Trade
        </Button>
      </form>

      <Flex direction="column" align="center" mt={2} p={2} borderRadius="md" boxShadow="sm" borderWidth="1px" borderColor="gray.200">
  <Flex justifyContent="space-between" alignItems="center" w="full" mb={1}>
    <Text fontSize="sm" fontWeight="bold" w="50%" textAlign="left">
      ETH Balance:
    </Text>
    <Text fontSize="sm" w="50%" textAlign="right">
      {(userEthBalance / 1e18).toFixed(3)} ETH
    </Text>
  </Flex>
  <Flex justifyContent="space-between" alignItems="center" w="full">
    <Text fontSize="sm" fontWeight="bold" w="50%" textAlign="left">
      {tokenData?.symbol} Balance:
    </Text>
    <Text fontSize="sm" w="50%" textAlign="right">
      {(userTokenBalance / 1e18).toFixed(0)}
    </Text>
  </Flex>
</Flex>


      <Text mt={4}>{tokenData?.name} (ticker: {tokenData?.symbol})</Text>
      <Image 
        src={tokenData?.imageUrl} 
        alt={tokenData?.name} 
        borderRadius="md" 
        mt={4} 
      />
      <Text fontSize="sm" mt={2}>
        {tokenData?.symbol}
      </Text>

      <Flex mt={4} justifyContent="center">
        <Link href="https://twitter.com/yourtoken" isExternal>
          <IconButton
            aria-label="Twitter"
            icon={<FaTwitter />}
            variant="ghost"
            size="lg"
            colorScheme="twitter"
            mr={2}
          />
        </Link>
        <Link href="https://t.me/yourtoken" isExternal>
          <IconButton
            aria-label="Telegram"
            icon={<FaTelegramPlane />}
            variant="ghost"
            size="lg"
            colorScheme="telegram"
            mr={2}
          />
        </Link>
        <Link href="https://yourtoken.com" isExternal>
          <IconButton
            aria-label="Website"
            icon={<FaGlobe />}
            variant="ghost"
            size="lg"
            colorScheme="blue"
          />
        </Link>
      </Flex>
    </>
  );
};

export default Trading;
