import React, { useRef, useEffect } from 'react';
import { createChart } from 'lightweight-charts';

const CustomChart = ({ data, timeframe }) => {
  const chartContainerRef = useRef(null);
  const priceToMcap = (price) => {
    return parseFloat((price * 100000000 * 2400).toFixed(3)); 
  }
  useEffect(() => {
    if (chartContainerRef.current) {
      const chart = createChart(chartContainerRef.current, {
        width: chartContainerRef.current.clientWidth,
        height: chartContainerRef.current.clientHeight,
        layout: {
          background: {
            color: '#0d1117', // Dark background
          },
          textColor: '#ffffff', // White text
        },
        grid: {
          vertLines: {
            color: '#2D3748', // Dark vertical grid lines
          },
          horzLines: {
            color: '#2D3748', // Dark horizontal grid lines
          },
        },
        crossHair: {
          mode: 0,
        },
        priceScale: {
          borderColor: '#2D3748', // Dark border for price scale
        },
        timeScale: {
            borderColor: '#2D3748', // Dark border for time scale
            timeVisible: true, // Ensure time is visible
            tickMarkFormatter: (time) => {
                const date = new Date(time * 1000);
                return date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }); // Format time as HH:mm
              },
            // Add this to ensure the chart is centered
            minBarSpacing: 10, // Increase spacing between bars if needed
            rightOffset: 10, // Adjust this to make sure the chart is not shifted to the right
          },
      });

      const candlestickSeries = chart.addCandlestickSeries({
        upColor: '#00FF00', // Green color for upward candles
        borderUpColor: '#00FF00', // Green border for upward candles
        wickUpColor: '#00FF00', // Green wick for upward candles
        downColor: '#FF0000', // Red color for downward candles
        borderDownColor: '#FF0000', // Red border for downward candles
        wickDownColor: '#FF0000', // Red wick for downward candles
      });

      // Filter data based on the selected timeframe
      const filteredData = data[timeframe].map(item => ({
        time: new Date(item.time).getTime() / 1000, // Convert date to timestamp
        open: priceToMcap(item.open),
        high: priceToMcap(item.high),
        low: priceToMcap(item.low),
        close: priceToMcap(item.close),
        volume: item.volume
      }));

      candlestickSeries.setData(filteredData);

      const resizeObserver = new ResizeObserver(() => {
        chart.resize(chartContainerRef.current.clientWidth, chartContainerRef.current.clientHeight);
      });

      resizeObserver.observe(chartContainerRef.current);

      return () => {
        resizeObserver.disconnect();
        chart.remove();
      };
    }
  }, [data, timeframe]);

  return (
    <div
      ref={chartContainerRef}
      style={{
        position: 'relative',
        width: '100%',
        height: '500px',
        backgroundColor: '#000000', // Ensure dark background
      }}
    />
  );
};

export default CustomChart;
