import { extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const theme = extendTheme({
  // Global styles
  styles: {
    global: (props) => ({
      body: {
        fontFamily: 'Press Start 2P, sans-serif',
        bg: mode('#F0F0F3', '#1A202C')(props),
        color: mode('#333333', '#FFFFFF')(props),
      },
    }),
  },
  // Custom fonts
  fonts: {
    heading: "'Press Start 2P', sans-serif",
    body: "'Press Start 2P', sans-serif",
  },
  // Enhanced colors
  colors: {
    customBlue: {
      500: '#3B82F6',
      600: '#2563EB',
      700: '#1D4ED8',
    },
    customRed: {
      500: '#EF4444',
      600: '#DC2626',
      700: '#B91C1C',
    },
    customGreen: {
      500: '#22C55E',
      600: '#16A34A',
      700: '#15803D',
    },
    customYellow: {
      500: '#FACC15',
      600: '#EAB308',
      700: '#CA8A04',
    },
    customGray: {
      500: '#9CA3AF',
      700: '#4B5563',
    },
  },
  // Custom border radius (pixelated corners)
  radii: {
    sm: '0px',
    md: '0px',
    lg: '0px',
  },
  // Custom shadows (blocky, 8-bit style)
  shadows: {
    outline: '0 0 0 3px #22C55E',
    base: '4px 4px 0px #000',
  },
  // Custom component styles
  components: {
    Button: {
      baseStyle: {
        borderRadius: '0px',
        textTransform: 'uppercase',
        boxShadow: '4px 4px 0px #000',
      },
      variants: {
        solid: (props) => ({
          bg: mode('#3B82F6', '#22C55E')(props),
          color: '#ffffff',
          _hover: {
            bg: mode('#2563EB', '#16A34A')(props),
          },
        }),
        outline: {
          border: '2px solid',
          borderColor: 'currentColor',
          boxShadow: '4px 4px 0px #000',
        },
      },
    },
    Input: {
      baseStyle: {
        borderRadius: '0px',
        boxShadow: '4px 4px 0px #000',
      },
      variants: {
        outline: {
          field: {
            borderColor: 'customGray.500',
            borderWidth: '2px',
            _hover: {
              borderColor: 'customGray.700',
            },
          },
        },
      },
    },
    Tabs: {
      baseStyle: {
        tab: {
          borderRadius: '0px',
          boxShadow: '4px 4px 0px #000',
          _selected: {
            bg: 'customBlue.500',
            color: '#ffffff',
            boxShadow: 'none',
          },
          _hover: {
            bg: 'customBlue.600',
          },
        },
        tabpanel: {
          borderRadius: '0px',
          boxShadow: '4px 4px 0px #000',
          bg: 'customGray.500',
        },
      },
    },
    Card: {
      baseStyle: {
        borderRadius: '0px',
        boxShadow: '4px 4px 0px #000',
        border: '2px solid #333',
        padding: '16px',
        backgroundColor: 'customGray.700',
      },
    },
  },
});

export default theme;
