import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import Login from './Login';
import TokenCreationModal from './TokenCreationModal';
import DepositEthModal from './DepositEthModal';
import WithdrawEthModal from './WithdrawEthModal';

const Header = ({ userData, setUserData, setIsLoggedIn }) => {
  return (
    <Flex 
      justifyContent={{ base: 'center', md: 'space-between' }} 
      alignItems="center" 
      mb={4} 
      flexDirection={{ base: 'column', md: 'row' }}
      wrap="wrap"
    >
      <Box mb={{ base: 2, md: 0 }}>
        <TokenCreationModal />
      </Box>
      <Flex
        direction={{ base: 'row', md: 'row' }}
        align="center"
        justify="center"
        wrap="wrap"
        gap={4}
      >
        {userData ? (
          <>
            <DepositEthModal />
            <WithdrawEthModal />
          </>
        ) : (
          <Login setIsLoggedIn={setIsLoggedIn} setUserData={setUserData} />
        )}
      </Flex>
    </Flex>
  );
};

export default Header;
