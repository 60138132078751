import React, { useEffect } from 'react';
import { SiweMessage } from 'siwe';
import { Button, HStack } from '@chakra-ui/react'; // Import HStack
import { useAccount, useConnect, useSignMessage } from 'wagmi';
import { ConnectKitButton } from 'connectkit';
import { BACKEND_URL } from '../../utils/constants';

const Login = ({ setUserData, setIsLoggedIn }) => {
    const { address, isConnected } = useAccount(); // Get wallet connection state and address
    const { connect } = useConnect(); // Use connect method to connect wallets
    const { signMessageAsync } = useSignMessage(); // For signing messages
    
    const domain = window.location.host;
    const origin = window.location.origin;
    
    useEffect(() => {
        if (isConnected) {
            getInformation();
        }
    }, [isConnected]);
    
    // Create a SIWE message to sign
    async function createSiweMessage(address, statement) {
        const res = await fetch(`${BACKEND_URL}/user/nonce`, {
            credentials: 'include',
        });
        const message = new SiweMessage({
            domain,
            address,
            statement,
            uri: origin,
            version: '1',
            chainId: '1',
            nonce: await res.text()
        });
        return message.prepareMessage();
    }

    // Sign in function
    async function signInWithEthereum() {
        try {
            const message = await createSiweMessage(address, 'Sign in with Ethereum to UNIPUMP');
            const signature = await signMessageAsync({ message });
            
            const res = await fetch(`${BACKEND_URL}/user/verify`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ message, signature }),
                credentials: 'include',
            });

            if (res.ok) {
                await getInformation();
            }
        } catch (error) {
            console.error('Sign-in failed', error);
        }
    }

    // Sign out function
    async function signOut() {
        const res = await fetch(`${BACKEND_URL}/user/logout`, {
            credentials: 'include',
        });
        console.log(await res.text());
        setIsLoggedIn(false);
        setUserData(null);
    }

    // Fetch user information
    async function getInformation() {
        const res = await fetch(`${BACKEND_URL}/user/getUser`, {
            credentials: 'include',
        });
        const _res = await res.json();
        if (_res.success) {
            setUserData(_res.data);
            setIsLoggedIn(true);
        }
    }

    return (
        <HStack spacing={4}> {/* Use HStack to align buttons horizontally */}
            {/* ConnectKitButton automatically handles wallet connection */}
            <ConnectKitButton />
            
            {/* Render login button only if wallet is connected */}
            {isConnected && (
                <Button colorScheme="blue" onClick={signInWithEthereum}>
                    Login
                </Button>
            )}
        </HStack>
    );
};

export default Login;
