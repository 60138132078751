import React, { useState, useEffect, useRef } from 'react';
import { Box, Flex, Text, Input, Button, VStack } from '@chakra-ui/react';
import axios from 'axios';
import { BACKEND_URL } from '../../utils/constants';

const Chatbox = ({ userData, tokenData }) => {
  const [message, setMessage] = useState('');
  const lastMessageRef = useRef(null); // Ref for the last message

  useEffect(() => {
    // Scroll the last message into view
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth',block:'nearest'});
    }
  }, [tokenData?.chatMessages]);

  const handleSend = async () => {
    if (message.trim()) {
      try {
        const response = await axios.post(
          `${BACKEND_URL}/token/chat`,
          { 
            tokenId: tokenData.tokenId,
            senderAddress: userData.userAddress,
            message: message,
            timestamp: new Date().toISOString(),
          },
          {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.status === 200) {
          setMessage('');
        } else {
          console.error('Failed to send message:', response.data);
        }
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
  };

  const shortenAddress = (address) => address.slice(0, 5) + '...';

  return (
    <Box bg="gray.800" p={4} width="100%" maxWidth="600px" mx="auto" height="450px" display="flex" flexDirection="column">
      {/* Chat Messages Container */}
      <VStack spacing={4} align="stretch" overflowY="auto" flex="1">
        {tokenData && tokenData.chatMessages.map((msg, index) => (
          <Box
            key={index}
            ref={index === tokenData.chatMessages.length - 1 ? lastMessageRef : null} // Attach ref to last message
            bg={userData && msg.senderAddress === userData.userAddress ? 'blue.500' : 'gray.700'}
            color="white"
            p={3}
            borderRadius="md"
            alignSelf={userData && msg.senderAddress === userData.userAddress ? 'flex-end' : 'flex-start'}
          >
            <Text fontWeight="bold">{shortenAddress(msg.senderAddress)}</Text>
            <Text>{msg.message}</Text>
            <Text fontSize="xs" color="gray.300">{new Date(msg.timestamp).toLocaleTimeString()}</Text>
          </Box>
        ))}
      </VStack>
      
      {/* Input for sending messages */}
      <Flex mt={4} alignItems="center">
        <Input
          placeholder="Type a message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          mr={2}
          bg="gray.700"
          color="white"
        />
        <Button colorScheme="blue" onClick={handleSend}>
          Send
        </Button>
      </Flex>
    </Box>
  );
};

export default Chatbox;
