import React from 'react';
import { Box, Button, Flex, Text, Link } from '@chakra-ui/react';
import Header from '../../components/common/Header';

const TokenNotFound = () => {
  return (
    <Box bg="#1A202C" color="white" minH="100vh" p={5}>
      {/* Top Bar */}
      <Header />

      {/* Main Content */}
      <Flex direction="column" align="center" justify="center" h="full">
        {/* 404 Message */}
        <Box bg="#2D3748" p={8} borderRadius="md" textAlign="center">
          <Text fontSize="4xl" fontWeight="bold" mb={4}>
            404 - Token Not Found
          </Text>
          <Text fontSize="lg" mb={6}>
            The token you're looking for doesn't exist or couldn't be found.
          </Text>
          <Link href="/" _hover={{ textDecoration: 'none' }}>
            <Button colorScheme="blue" size="lg">
              Go Back to Homepage
            </Button>
          </Link>
        </Box>
      </Flex>
    </Box>
  );
};

export default TokenNotFound;
