import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Progress,
  VStack,
  HStack,
  Avatar,
  Link as ChakraLink,
} from '@chakra-ui/react';
import Header from '../../components/common/Header';
import { BACKEND_WS_URL, ETH_PRICE, MIN_MIGRATION_ETH } from '../../utils/constants';

const Homepage = () => {
  const [userData, setUserData] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [newTokens, setNewTokens] = useState([]);
  const [aboutToGraduate, setAboutToGraduate] = useState([]);

  useEffect(() => {
    const socket = new WebSocket(`${BACKEND_WS_URL}`);

    socket.onopen = () => {
      socket.send(
        JSON.stringify({
          action: 'subscribe',
          type: 'newTokens',
        })
      );
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === 'newTokens') {
        setNewTokens((prevTokens) => {
          const newUniqueTokens = data.tokens.filter(
            (newToken) =>
              !prevTokens.some((token) => token.tokenId === newToken.tokenId)
          );
          return [...newUniqueTokens, ...prevTokens];
        });
      }

      if (data.type === 'aboutToGraduate') {
        setAboutToGraduate((prevTokens) => {
          const newUniqueTokens = data.tokens.filter(
            (newToken) =>
              !prevTokens.some((token) => token.tokenId === newToken.tokenId)
          );
          return [...newUniqueTokens, ...prevTokens];
        });
      }
    };

    socket.onclose = () => {
      socket.send(
        JSON.stringify({
          action: 'unsubscribe',
          type: 'newTokens',
        })
      );
    };

    return () => {
      socket.close();
    };
  }, []);

  return (
    <Box bg="#1A202C" color="white" minH="100vh" p={5}>
      <Header
        userData={userData}
        setUserData={setUserData}
        setIsLoggedIn={setIsLoggedIn}
      />
      <Box pt={4} pb={4}>
        <Text fontSize="2xl" fontWeight="bold">
          UNIPUMP
        </Text>
        <Text>
          Solving fair token launch on eth.
        </Text>
      </Box>

      <Flex justify="space-between" gap={4}>
        {/* New Creations Section */}
        <Box
          flex="40"
          bg="#2D3748"
          p={4}
          borderRadius="md"
          mb={{ base: 4, md: 0 }}
          boxShadow="base"
        >
          <Text fontSize="xl" mb={4}>
            New Creations
          </Text>
          {newTokens.map((token, index) => (
            <ChakraLink href={`/${token.tokenId}`} key={index} isExternal>
              <VStack
                align="start"
                spacing={4}
                mb={4}
                p={3}
                bg="#1A202C"
                borderRadius="md"
                boxShadow="base"
              >
                <HStack justify="space-between" w="full">
                  <Avatar name={token.name} src={token.imageUrl} />
                  <VStack align="start" spacing={0}>
                    <Text fontWeight="bold">{token.name}</Text>
                    <Text>Symbol: {token.symbol}</Text>
                    <Text>
                      Market Cap: ${(ETH_PRICE * token.mcap).toFixed(0)}
                    </Text>
                    <Text>
                      Total Volume: $
                      {(ETH_PRICE * token.totalVolume / 1e18).toFixed(1)}
                    </Text>
                  </VStack>
                </HStack>
                <Progress
                  value={
                    100 * (token.realReserves.eth / MIN_MIGRATION_ETH) || 0
                  }
                  size="sm"
                  w="full"
                  colorScheme="green"
                />
              </VStack>
            </ChakraLink>
          ))}
        </Box>

        {/* About to Graduate Section */}
        <Box
          flex="40"
          bg="#2D3748"
          p={4}
          borderRadius="md"
          mb={{ base: 4, md: 0 }}
          boxShadow="base"
        >
          <Text fontSize="xl" mb={4}>
            About to Graduate
          </Text>
          {aboutToGraduate.map((token, index) => (
            <ChakraLink href={`/${token.tokenId}`} key={index} isExternal>
              <VStack
                align="start"
                spacing={4}
                mb={4}
                p={3}
                bg="#1A202C"
                borderRadius="md"
                boxShadow="base"
              >
                <HStack justify="space-between" w="full">
                  <Avatar name={token.name} src={token.imageUrl} />
                  <VStack align="start" spacing={0}>
                    <Text fontWeight="bold">{token.name}</Text>
                    <Text>Symbol: {token.symbol}</Text>
                    <Text>
                      Market Cap: ${(ETH_PRICE * token.mcap).toFixed(0)}
                    </Text>
                    <Text>
                      Total Volume: $
                      {(ETH_PRICE * token.totalVolume / 1e18).toFixed(1)}
                    </Text>
                  </VStack>
                </HStack>
                <Progress
                  value={
                    100 * (token.realReserves.eth / MIN_MIGRATION_ETH) || 0
                  }
                  size="sm"
                  w="full"
                  colorScheme="green"
                />
              </VStack>
            </ChakraLink>
          ))}
        </Box>
      </Flex>
    </Box>
  );
};

export default Homepage;
