// DisplayPage.js
import React from 'react';
import { Box, Button, Heading, Text, VStack } from '@chakra-ui/react';

const DisplayPage = () => {
  return (
    <Box
      backgroundColor="background"
      color="text"
      minHeight="100vh"
      padding="20px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Heading mb="4">8-Bit Style Theme</Heading>
      <VStack spacing="6">
        <Box>
          <Heading size="lg" mb="2">Button Variants</Heading>
          <Button variant="solidGreen" mr="4">Solid Green Button</Button>
          <Button variant="solidRed">Solid Red Button</Button>
        </Box>
        <Box>
          <Heading size="lg" mb="2">Outline Button Variants</Heading>
          <Button variant="outlineGreen" mr="4">Outline Green Button</Button>
          <Button variant="outlineRed">Outline Red Button</Button>
        </Box>
        <Box>
          <Heading size="lg" mb="2">Text Elements</Heading>
          <Text fontSize="lg" mb="2">This is a sample text with the 8-bit font style.</Text>
        </Box>
        <Box>
          <Heading size="lg" mb="2">Card Component</Heading>
          <Box
            padding="4"
            borderWidth="2px"
            borderColor="border"
            borderRadius="0"
            backgroundColor="background"
          >
            <Heading size="md" mb="2">Card Title</Heading>
            <Text>This is a card component styled in 8-bit style.</Text>
          </Box>
        </Box>
      </VStack>
    </Box>
  );
};

export default DisplayPage;
