export const BACKEND_URL = "https://unipump.app/api"
export const BACKEND_WS_URL= "wss://unipump.app/api/"
export const BONDING_MCAP = 50000
export const MIN_MIGRATION_ETH=4000000000000000000
export const ETH_PRICE=2400
export const MAINNET_RPC = "https://mainnet.infura.io/v3/6aa9758963f14a5daba1f987750df952"
export const SEPOLIA_RPC = "https://sepolia.infura.io/v3/6aa9758963f14a5daba1f987750df952"
export const UNIPUMP_CONTRACT_ADDRESS="0x6bD784a652D30ac1e478E565a5be01e241811d6f"
export const UNIPUMP_ABI = [
    "function deposit() payable"
];