import React from 'react';
import { Box, Text, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';

const TradesTable = ({ trades }) => {
  const sortedTrades = trades.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
  console.log(sortedTrades)
  const getTimeAgo = (dateString) => {
    const now = new Date();
    const tradeDate = new Date(dateString);
    const diffInSeconds = Math.floor((now - tradeDate) / 1000);

    if (diffInSeconds < 60) {
      return `${diffInSeconds} sec ago`;
    } else if (diffInSeconds < 3600) {
      return `${Math.floor(diffInSeconds / 60)} min ago`;
    } else if (diffInSeconds < 86400) {
      return `${Math.floor(diffInSeconds / 3600)} hr ago`;
    } else {
      return `${Math.floor(diffInSeconds / 86400)} day ago`;
    }
  };

  const trimAddress = (address) => {
    return address.length > 5 ? `${address.slice(0, 5)}...` : address;
  };

  const formatVolume = (volume) => {
    return (volume / 1e18).toFixed(3);
  };

  const priceToMcap = (price) => {
    return (price * 100000000 * 2400).toFixed(3); 
  }

  return (
    <Box
      bg="gray.800"
      p={3}
      shadow="md"
      flex="1"
    >
      <Text fontSize="lg" mb={3} fontWeight="semibold">Trades</Text>
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            <Th fontSize="sm">Type</Th>
            <Th fontSize="sm">Volume (ETH)</Th>
            <Th fontSize="sm">MCAP</Th>
            <Th fontSize="sm">Date</Th>
          </Tr>
        </Thead>
        <Tbody>
          <AnimatePresence>
            {sortedTrades.map((tick, index) => (
              <motion.tr
                key={index}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.3 }}
              >
                <Td fontSize="sm">{tick.side}</Td>
                <Td fontSize="sm">{formatVolume(tick.volume.eth)}</Td>
                <Td fontSize="sm">{priceToMcap(tick.price)}</Td>
                <Td fontSize="sm">{getTimeAgo(tick.timestamp)}</Td>
              </motion.tr>
            ))}
          </AnimatePresence>
        </Tbody>
      </Table>
    </Box>
  );
};

export default TradesTable;
