import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormLabel,
  FormControl,
  Textarea,
  useDisclosure,
  Box,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { BACKEND_URL } from '../../utils/constants';

const TokenCreationModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { register, handleSubmit, setValue } = useForm();
  const toast = useToast();
  const navigate = useNavigate();


  const onSubmit = async (data) => {
    // Create FormData object
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('symbol', data.symbol);
    formData.append('description', data.description);
    if (data.image[0]) {
      formData.append('image', data.image[0]);
    }

    try {
      const response = await fetch(`${BACKEND_URL}/token/create`, {
        method: 'POST',
        body: formData,
        credentials: 'include', // This includes cookies with the request
      });      

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();

      // Show success toast
      toast({
        title: 'Token created.',
        description: `Token "${data.name}" with symbol "${data.symbol}" has been successfully created.`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      let tokenId = result.tokenId
      navigate(`/${tokenId}`);

      console.log(result);
      // Close the modal
      onClose();
    } catch (error) {
      // Show error toast
      toast({
        title: 'Error creating token.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Button onClick={onOpen}>Create New Token</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent mt="1">
          <ModalHeader>Create a New Token</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl id="name" mb={4}>
                <FormLabel>Name</FormLabel>
                <Input
                  {...register('name', { required: true })}
                  placeholder="Token Name"
                  borderRadius="0"
                  boxShadow="4px 4px 0px #000"
                  borderColor="customGray.500"
                  _hover={{ borderColor: 'customGray.700' }}
                />
              </FormControl>

              <FormControl id="symbol" mb={4}>
                <FormLabel>Symbol</FormLabel>
                <Input
                  {...register('symbol', { required: true })}
                  placeholder="Token Symbol"
                  borderRadius="0"
                  boxShadow="4px 4px 0px #000"
                  borderColor="customGray.500"
                  _hover={{ borderColor: 'customGray.700' }}
                />
              </FormControl>

              <FormControl id="description" mb={4}>
                <FormLabel>Description</FormLabel>
                <Textarea
                  {...register('description')}
                  placeholder="Token Description"
                  borderRadius="0"
                  boxShadow="4px 4px 0px #000"
                  borderColor="customGray.500"
                  _hover={{ borderColor: 'customGray.700' }}
                />
              </FormControl>

              <FormControl id="image" mb={4}>
                <FormLabel>Upload Image</FormLabel>
                <Box position="relative">
                  <Input
                    type="file"
                    {...register('image')}
                    opacity="0"
                    position="absolute"
                    top="0"
                    left="0"
                    height="100%"
                    width="100%"
                    zIndex="1"
                    cursor="pointer"
                  />
                  <Button
                    as="span"
                    bg="customBlue.500"
                    color="white"
                    borderRadius="0"
                    boxShadow="4px 4px 0px #000"
                    _hover={{
                      bg: 'customBlue.600',
                    }}
                    _active={{
                      bg: 'customBlue.700',
                      transform: 'translateY(2px)',
                    }}
                  >
                    Choose File
                  </Button>
                </Box>
              </FormControl>

              <Button type="submit" colorScheme="blue">
                Create Token
              </Button>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TokenCreationModal;
